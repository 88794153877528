var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',{attrs:{"overflow-auto":""}},[_vm._v(" Jobs ")]),_c('v-spacer')],1),_c('v-card',[_c('FeathersServiceTable',{staticClass:"elevation-1",attrs:{"service":"jobs","params":_vm.jobParams,"headers":_vm.headers,"loading":_vm.jobLoadPending,"sort-by":['status', 'priority', 'created'],"sort-desc":[false, false, false],"must-sort":"","multi-sort":""},scopedSlots:_vm._u([{key:"item.priority",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"pa-1",attrs:{"value":item.priority,"type":"number","min":"0","solo":"","single-line":"","hide-details":""},on:{"change":function($event){return _vm.updateJobPriority(item.id, $event)}}})]}},{key:"item.case_id",fn:function(ref){
var case_id = ref.item.case_id;
return [_c('FeathersVuexGet',{attrs:{"service":"cases","id":case_id,"params":{ query: { $select: ['name', 'scenario_id'] } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.gotoCase(item)}},[_vm._v(" "+_vm._s(item && item.name ? item.name : item)+" ")])]}}],null,true)})]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [_c('FeathersVuexGet',{attrs:{"service":"users","id":item.user_id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item ? item.email : item)+" ")]}}],null,true)})]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.dateWithBreak(item.created))}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.modules.find(function (el) { return el.type === item.type; }).name)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('JobIndicator',{attrs:{"job":item,"jobType":item.type}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }