<template>
  <v-container fluid class="pa-0">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title overflow-auto> Deltas </v-toolbar-title>
      <v-spacer></v-spacer>
      <DialogForm title="New Delta" icon="add" max-width="800" :submit="addDelta">
        <template #default>
          <v-row>
            <v-col cols="2" class="pt-6"> Name </v-col>
            <v-col class="grow">
              <v-text-field
                v-model="newDelta.name"
                :rules="[$utils.validation.required]"
                class="pt-0"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col> Case A </v-col>
            <SelectScenario
              v-model="newDelta.caseA_scenario"
              :rules="[$utils.validation.required]"
              solo
              hide-create
              hide-rename
              hide-save-as
            ></SelectScenario>
            <SelectCase
              v-model="newDelta.caseA_id"
              :scenario_id="newDelta.caseA_scenario"
              :rules="[$utils.validation.required]"
              solo
              hide-create
              hide-rename
              hide-save-as
              @input="retrieveJobs(newDelta.caseA_id)"
            ></SelectCase>
          </v-row>
          <v-row>
            <v-col> Case B </v-col>
            <SelectScenario
              v-model="newDelta.caseB_scenario"
              :rules="[$utils.validation.required]"
              solo
              hide-create
              hide-rename
              hide-save-as
            ></SelectScenario>
            <SelectCase
              v-model="newDelta.caseB_id"
              :scenario_id="newDelta.caseB_scenario"
              :rules="[$utils.validation.required]"
              solo
              hide-create
              hide-rename
              hide-save-as
              @input="retrieveJobs(newDelta.caseB_id)"
            ></SelectCase>
          </v-row>

          <v-row>
            <v-col> Calculation Type </v-col>
            <v-select
              v-model="newDelta.type"
              :rules="[$utils.validation.required]"
              :items="modules"
              item-text="name"
              item-value="type"
              solo
              label="Module"
            ></v-select>
          </v-row>
        </template>
      </DialogForm>
    </v-toolbar>
    <FeathersServiceTable
      service="deltas"
      :headers="headers"
      :params="deltasParams"
      show-expand
      useClonedItems
    >
      <template #item.name="props">
        <v-text-field v-model="props.item.new.name" solo flat></v-text-field>
      </template>

      <template #item.status="props">
        <v-tooltip top v-if="props.item.new.error">
          <template #activator="{ on }">
            <v-icon color="error" v-on="on"> error </v-icon>
          </template>
          <span>{{ shortError(props.item.new) }}</span>
        </v-tooltip>
        <v-icon v-else color="success">check_circle</v-icon>
      </template>

      <template #item.caseA="props">
        <SelectScenario
          v-model="props.item.new.caseA_scenario"
          flat
          solo
          hide-details
          hide-create
          hide-rename
          hide-save-as
          dense
        ></SelectScenario>
        <SelectCase
          v-model="props.item.new.caseA_id"
          :scenario_id="props.item.new.caseA_scenario"
          flat
          solo
          hide-details
          hide-create
          hide-rename
          hide-save-as
          dense
          @input="retrieveJobs(props.item.new.caseA_id)"
        ></SelectCase>
      </template>
      <template #item.caseB="props">
        <SelectScenario
          v-model="props.item.new.caseB_scenario"
          flat
          solo
          hide-details
          hide-create
          hide-rename
          hide-save-as
          dense
        ></SelectScenario>
        <SelectCase
          v-model="props.item.new.caseB_id"
          :scenario_id="props.item.new.caseB_scenario"
          flat
          solo
          hide-details
          hide-create
          hide-rename
          hide-save-as
          @input="retrieveJobs(props.item.new.caseB_id)"
        ></SelectCase>
      </template>
      <template #item.module="props">
        <v-select
          v-model="props.item.new.type"
          :items="modules"
          item-text="name"
          item-value="type"
          flat
          solo
          dense
          label="Module"
        ></v-select>
      </template>
      <template #item.actions="props">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn
              icon
              :disabled="!itemHasChanged(props.item.new) || !!isInvalidDelta(props.item.new)"
              v-on="on"
              @click="saveDelta(props.item.new)"
            >
              <v-icon>save</v-icon>
            </v-btn>
          </template>
          <span> Save </span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon v-on="on" @click.stop="copyDelta(props.item.new)">
              <v-icon>content_copy</v-icon>
            </v-btn>
          </template>
          <span> Copy </span>
        </v-tooltip>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon v-on="on" @click="removeDelta(props.item.old)">
              <v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span> Delete </span>
        </v-tooltip>
      </template>
      <template #expanded-item="props">
        <td :colspan="headers.length + 1">
          <DataTableYearly
            v-if="props.item.old.delta"
            :items="deltaItems(props.item.old)"
          ></DataTableYearly>
          <template v-else>
            <v-alert
              v-if="
                itemHasChanged(props.item.old, props.item.new) && !isInvalidDelta(props.item.new)
              "
              color="success"
              >Valid Delta</v-alert
            >
            <v-alert v-else type="error" class="mt-2">{{
              itemHasChanged(props.item.old, props.item.new)
                ? isInvalidDelta(props.item.new)
                : props.item.new.error.message
            }}</v-alert>
          </template>
        </td>
      </template>
    </FeathersServiceTable>
  </v-container>
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapGetters, mapActions } from "vuex";
import { makeFindMixin } from "feathers-vuex";

export default {
  name: "Deltas",
  components: {
    DataTableYearly: () => import("@/components/DataTableYearly"),
    DialogForm: () => import("@/components/DialogForm"),
    FeathersServiceTable: () => import("@/components/FeathersServiceTable"),
    SelectScenario: () => import("@/components/SelectScenario"),
    SelectCase: () => import("@/components/SelectCase"),
  },
  mixins: [makeFindMixin({ service: "deltas", watch: "params" })],
  props: {},
  data() {
    return {
      newDelta: {
        name: "",
        caseA_scenario: null,
        caseA_id: null,
        caseB_scenario: null,
        caseB_id: null,
        type: null,
      },
      headers: [
        { text: "Name", value: "name" },
        {
          text: "Case A",
          value: "caseA",
          align: "center",
          sortable: false,
        },
        {
          text: "Case B",
          value: "caseB",
          align: "center",
          sortable: false,
        },
        {
          text: "Module",
          value: "module",
          align: "center",
          sortable: false,
        },
        { text: "Status", value: "status", sortable: false },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      modules: [
        {
          name: "Alternative Analysis",
          value: "ALTERNATIVE_ANALYSIS",
          type: 1,
        },
        {
          name: "Production Cost",
          value: "PRODUCTION_COST",
          type: 2,
        },
        {
          name: "AA + PC",
          type: 3,
        },
        /* {
          name: 'Stacked Services Emulator',
          value: 'STACKED_SERVICES_EMULATOR',
          type: 5,
        }, */
      ],
    };
  },
  computed: {
    ...mapGetters({
      getJobs: "jobs/find",
    }),
    deltasParams() {
      return {
        query: {
          $sort: {
            id: -1,
          },
        },
      };
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions({
      loadJobs: "jobs/find",
      createDelta: "deltas/create",
      updateDelta: "deltas/patch",
    }),
    removeDelta(delta) {
      // eslint-disable-next-line no-alert
      if (window.confirm("Are you sure you want to delete this Delta?")) {
        delta.remove();
      }
    },
    retrieveJobs(caseId) {
      this.loadJobs({
        query: { case_id: caseId, $select: ["id", "case_id", "type"] },
      });
    },
    addDelta() {
      return this.createDelta(this.newDelta).then(() => {
        this.newDelta = {
          caseA_scenario: null,
          caseA_id: null,
          caseB_scenario: null,
          caseB_id: null,
          type: null,
        };
      });
    },
    saveDelta(item) {
      return item.save().then(() => {
        window.location.reload();
      });
    },
    copyDelta(item) {
      const copy = { ...item, name: `${item.name} Copy` };
      delete copy.id;
      delete copy.created_at;
      delete copy.updated_at;
      if (copy.error) delete copy.error;
      return this.createDelta(copy);
    },
    shortError(item) {
      if (item.error) {
        return item.error.message && item.error.message.includes("outputs")
          ? "Error: missing output"
          : "Error";
      }
      return "";
    },
    isInvalidDelta(item) {
      const errors = [];
      if (item.caseA_id == null || item.caseB_id == null) {
        errors.push("Select Cases");
      }
      if (item.caseA_id === item.caseB_id) {
        errors.push("Same Cases Selected");
      }
      if (item.type == null) {
        errors.push("Select Calculation");
      }

      /*
      TODO: For type 3 (AA + PC) check that both cases have run both AA and PC
      const jobs = this.getJobs({
        query: {
          case_id: {
            $in: [item.caseA_id, item.caseB_id],
          },
          type: item.type,
          $limit: 0,
        },
      });

      if (jobs.total !== 2) {
        errors.push('Both cases have not run selected module');
      } */

      return errors.length ? errors : false;
    },
    itemHasChanged(before, after) {
      return JSON.stringify(before) !== JSON.stringify(after);
    },
    mapValue(source) {
      return (item) => ({ ...item, value: source[item.key] || null });
    },
    deltaItems(item) {
      const { delta } = item;
      if (delta === null) return [];
      return [
        { name: "Zonal Price ($/MWh)", key: "zonal_price" },
        {
          name: "Capacity Price ($/kW-yr)",
          key: "capacity_price",
        },
        { name: "Total Overnight", key: "total_overnight" },
        { name: "CO2 Emission (tons)", key: "co2_emissions" },
        { name: "Emissions Cost ($)", key: "co2_emissions_cost" },
        { name: "Generation Cost ($)", key: "generation_cost" },
        { name: "FOM Cost ($)", key: "fom_cost" },
        { name: "VOM Cost ($)", key: "vom_cost" },
        { name: "Fuel Cost ($)", key: "fuel_cost" },
        { name: "Firm Capacity (MW)", key: "firm_capacity" },
        {
          name: "Total Installed Capacity (MW)",
          key: "installed_capacity",
        },
        { name: "Capacity Margin (%)", key: "capacity_margin" },
        { name: "Objective Value ($)", key: "objective_key" },
        { name: "Load Cost ($)", key: "load_cost" },
      ].map(this.mapValue(delta));
    },
  },
};
</script>

<style></style>
