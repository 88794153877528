<template>
  <v-container fluid class="pa-0">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title overflow-auto> Jobs </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <template v-slot:extension>
        <v-tabs v-model="tabModel" background-color="primary" light centered>
          <v-tab>
            Queued
          </v-tab>
        </v-tabs>
      </template> -->
    </v-toolbar>
    <!-- <v-tabs-items v-model="tabModel">
      <v-tab-item> -->
    <v-card>
      <FeathersServiceTable
        service="jobs"
        :params="jobParams"
        :headers="headers"
        :loading="jobLoadPending"
        :sort-by="['status', 'priority', 'created']"
        :sort-desc="[false, false, false]"
        must-sort
        multi-sort
        class="elevation-1"
      >
        <template #item.priority="{ item }">
          <v-text-field
            :value="item.priority"
            class="pa-1"
            type="number"
            min="0"
            solo
            single-line
            hide-details
            @change="updateJobPriority(item.id, $event)"
          ></v-text-field>
        </template>
        <template #item.case_id="{ item: { case_id } }">
          <FeathersVuexGet
            service="cases"
            :id="case_id"
            :params="{ query: { $select: ['name', 'scenario_id'] } }"
          >
            <template #default="{ item }">
              <router-link :to="gotoCase(item)">
                {{ item && item.name ? item.name : item }}
              </router-link>
            </template>
          </FeathersVuexGet>
        </template>
        <template #item.user_id="{ item }">
          <FeathersVuexGet service="users" :id="item.user_id">
            <template #default="{ item }">
              {{ item ? item.email : item }}
            </template>
          </FeathersVuexGet>
        </template>
        <template #item.created="{ item }">
          <span v-html="dateWithBreak(item.created)"></span>
        </template>
        <template #item.type="{ item }">
          {{ modules.find((el) => el.type === item.type).name }}
        </template>
        <template #item.status="{ item }">
          <JobIndicator :job="item" :jobType="item.type"></JobIndicator>
        </template>
      </FeathersServiceTable>
    </v-card>
    <!-- </v-tab-item>
    </v-tabs-items> -->
  </v-container>
</template>

<script>
/* eslint-disable no-param-reassign */
import { mapGetters, mapActions } from "vuex";
import { FeathersVuexGet } from "feathers-vuex";

export default {
  name: "JobOverview",
  components: {
    JobIndicator: () => import("@/components/JobIndicator"),
    FeathersServiceTable: () => import("@/components/FeathersServiceTable"),
    FeathersVuexGet,
  },
  mixins: [
    /* makeFindMixin({ service: 'jobs', watch: 'params' }) */
  ],
  props: {},
  data() {
    return {
      tabModel: null,
      headers: [
        {
          text: "Priority",
          value: "priority",
          width: 100,
          sort: (a, b) => (a < b && a != null ? -1 : 1),
        },
        {
          text: "Started At",
          value: "created",
        },
        {
          text: "Started By",
          value: "user_id",
        },
        {
          text: "Case",
          value: "case_id",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Status",
          value: "status",
          // sort: (a, b) =>
        },
      ],
      modules: [
        {
          name: "Alternative Analysis",
          value: "ALTERNATIVE_ANALYSIS",
          type: 1,
        },
        {
          name: "Production Cost",
          value: "PRODUCTION_COST",
          type: 2,
        },
        {
          name: "Stacked Services Emulator",
          value: "STACKED_SERVICES_EMULATOR",
          type: 5,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      jobLoadPending: "jobs/loadPending",
      getCase: "cases/get",
      getUser: "users/get",
    }),
    jobParams() {
      return {
        query: {
          status: { $in: ["NOT_STARTED", "IN_PROGRESS"] },
          $sort: {
            created: 1,
            priority: 1,
            status: 1,
          },
        },
      };
    },
  },
  watch: {},
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  activated() {},
  deactivated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    ...mapActions({
      patchJob: "jobs/patch",
    }),
    gotoCase(item) {
      if (item == null) return {};
      const { scenario_id: scenario, id } = item;
      return {
        name: "CaseInput",
        params: { scenario, id },
      };
    },
    updateJobPriority(jobId, priority) {
      if (priority === "") priority = null;
      return this.patchJob([jobId, { priority }]);
    },
    dateWithBreak(date) {
      const datestamp = new Date(date);
      const day = datestamp.toLocaleDateString();
      const time = datestamp.toLocaleTimeString();
      return `${day} <wbr>${time}`;
    },
  },
};
</script>

<style></style>
